import $ from "jquery";

export const miHeaderSection = () => {
  const init = () => {
    $(document).on("scroll", function () {
      try {
        // console.log({
        //   scroll: $(window).scrollTop(),
        //   section: $("#top-banner").outerHeight(true),
        // });
        if ($(window).scrollTop() > $("#top-banner").outerHeight(true)) execScrollUp();
        else execScrollDown();
      } catch (err) {
        console.error(err);
      }
    });
  };

  const execScrollUp = () => {
    $(".main-header-section").attr(
      "style",
      "background-color: rgba(41,35,92,0.85)!important"
    );
  };

  const execScrollDown = () => {
    $(".main-header-section").attr(
      "style",
      "background-color: rgba(41,35,92,0.46)!important"
    );
  };
  init();
};
