import $ from "jquery";
import "slick-carousel";

export const miCarouselReviews = () => {
  const init = () => {
    $(document).ready(function () {
      try {
        exec();
      } catch (err) {
        console.error(err);
      }
    });
  };

  const exec = () => {
    $(".mi-carousel-reviews__container").slick({
      infinite: true,
      speed: 300,
      slidesToShow: 3,
      slidesToScroll: 3,
      centerMode: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });

  };

  init();
};
