// import { miOverOnsText } from "./animation/miOverOnsText";
import { miCarouselPartner } from "./custom/miCarouselPartner";
import { miCarouselReviews } from "./custom/miCarouselReviews";
import { miMogelijkhedenCarousel } from "./custom/miMogelijkhedenCarousel";
import { miHeaderSection } from "./styles/miHeaderSection";
import { miCarouselSuccesverhalen } from "./custom/miCarouselSuccesverhalen";
import { miCarouselReviewsTextMore } from "./animation/miCarouselReviewsTextMore";
import { miCarouselTrainers } from "./custom/miCarouselTrainers";
import { miCarouselCoaches } from "./custom/miCarouselCoaches";

// miOverOnsText();
miCarouselReviews();
miMogelijkhedenCarousel();
miCarouselPartner();
miHeaderSection();
miCarouselSuccesverhalen();
miCarouselReviewsTextMore();
miCarouselTrainers();
miCarouselCoaches();