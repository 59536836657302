import $ from "jquery";
import "slick-carousel";

export const miCarouselSuccesverhalen = () => {
  const init = () => {
    $(function () {
      try {
        exec();
      } catch (err) {
        console.error(err);
      }
    });
  };
  const exec = () => {
    $(".mi-carousel-succesverhalen__container").slick({
      infinite: false,
      speed: 300,
      slidesToShow: 2,
      slidesToScroll: 2,
      dots: true,
      // centerMode: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
  };
  init();
};
