import $ from "jquery";
import "slick-carousel";

export const miCarouselTrainers = () => {
  const init = () => {
    $(function () {
      try {
        exec();
        handleOnClickOpenPopup();
        handleOnClickClosePopup();
      } catch (err) {
        console.error(err);
      }
    });
  };

  const el = {
    thisCew: ".mi-carousel-trainers__container",
    thisCewItem: ".mi-carousel-trainers__main:not(.popup)",
    popupContainer: ".trainers-popup",
    popupOverlay: ".overlay",
    popupTriggerOpen: ".js-trainers-popup-open",
    popupTriggerClose: ".js-trainers-popup-close",
  };

  const exec = () => {
    $(".mi-carousel-trainers__container").slick({
      infinite: false,
      speed: 300,
      slidesToShow: 3,
      slidesToScroll: 3,
      dots: true,
      // centerMode: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
  };

  const handleOnClickOpenPopup = () => {
    $(document).on("click", el.popupTriggerOpen, (e) => {
      const thisEl = $(e.target).closest(el.thisCewItem);

      if (thisEl.attr("data-description").length === 0) return;

      const selectedCoachPicture = thisEl
        .find(".mi-carousel-trainers__image img")
        .attr("src");
      const selectedCoachFirstName = thisEl.find(
        ".mi-carousel-trainers__name p:nth-child(1)"
      )?.[0].outerText;
      const selectedCoachRestName = thisEl.find(
        ".mi-carousel-trainers__name p:nth-child(2)"
      )?.[0].outerText;
      const selectedCoachDesc = thisEl.attr("data-description");
      const popupElement = `
        <div class="${el.popupContainer.replace(".", "")}">
          <div class="overlay ${el.popupTriggerClose.replace(".", "")}"></div>
          <div class="mi-carousel-trainers__main popup">
            <div class="mi-carousel-trainers__image">
                <img decoding="async" src="${
                  selectedCoachPicture ? selectedCoachPicture : ""
                }" alt="Image" width="auto" height="auto">
            </div>
            <div class="mi-carousel-trainers__name">
                <p>${selectedCoachFirstName ? selectedCoachFirstName : ""}</p>
                <p>${selectedCoachRestName ? selectedCoachRestName : ""}</p>
            </div>
            <p class="mi-carousel-trainers__desc">
              ${selectedCoachDesc ? selectedCoachDesc : ""}
            </p>
            <div class="ic-close js-trainers-popup-close">
                <svg width="100%" height="100%" id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z"></path>
                </svg>
            </div>
          </div>
        </div>
      `;

      if ($("body").find(".trainers-popup").length !== 0)
        $("body").find(".trainers-popup").remove();
      thisEl.closest("body").append(popupElement);

      setTimeout(() => {
        $("body").find(".trainers-popup").addClass("visible");
      }, 10);
    });
  };

  const handleOnClickClosePopup = () => {
    $(document).on("click", el.popupTriggerClose, (e) => {
      const thisEl = $(e.target).closest(el.popupTriggerClose);
      const thisPopup = thisEl.closest("body").find(el.popupContainer);

      thisPopup.removeClass("visible");
      setTimeout(() => {
        thisPopup.remove();
      }, 300);
    });
  };

  init();
};
