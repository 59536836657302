import $ from "jquery";
import "slick-carousel";

export const miMogelijkhedenCarousel = () => {
  const init = () => {
    $(document).ready(function () {
      try {
        exec();
        adjustCarouselBtn();
      } catch (err) {
        console.error(err);
      }
    });

    $(window).on("orientationchange resize", function () {
      adjustCarouselBtn();
    });
  };
  const exec = () => {
    $(".mi-mogelijkheden__container").slick({
      infinite: true,
      speed: 300,
      adaptiveHeight: true,
      slidesToShow: 1,
      dots: true,
    });
  };

  const adjustCarouselBtn = () => {
    let parent = ".mi-mogelijkheden";
    $(parent + " .slick-prev").css({ left: 0 });
    $(parent + " .slick-next").css({ left: 0 });

    let leftLength =
      $(parent + " .slick-dots").offset().left -
      $(parent + " .slick-prev").offset().left -
      (window.innerWidth > 1024 ? 76 : 60);

    $(parent + " .slick-prev").css({
      left: leftLength,
    });

    $(parent + " .slick-next").css({
      left: leftLength + (window.innerWidth > 1024 ? 76 : 60) + 18 + $(parent + " .slick-dots").width(),
    });
  };
  init();
};
