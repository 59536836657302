import $ from "jquery";
export const miCarouselReviewsTextMore = () => {
  const init = () => {
    learnMore()
  }

  const button = ".js-text-more";
  const section = ".mi-carousel-reviews__main"

  const learnMore = () => {
    // console.log(button);
    $(document).on("click", button, function () {
      const idReview = $(this).parents(section).eq(0).attr("data-id")

      const selectedReview = $(this).closest(`[data-id='${idReview}']`);

      // const content = selectedReview.find(".mi-carousel-reviews__review p");
      expandElement($(this), selectedReview, 'show');
    })

    const expandElement = (button, elem, collapseClass) => {
      // debugger;
      elem.css('height', '');
      elem.css('transition', 'none');

      const startHeight = elem.css('height');

      // Remove the collapse class, and force a layout calculation to get the final height
      elem.toggleClass(collapseClass);
      button.toggleClass('rotate-after-180')
      if (elem.hasClass("show")) {
        button.text('Laat minder zien')
      }
      else {
        button.text('Lees verder')
      }
      // elem.addClass(collapseClass);
      const height = elem.css('height');

      // Set the start height to begin the transition
      elem.css('height', startHeight);

      // wait until the next frame so that everything has time to update before starting the transition
      requestAnimationFrame(() => {
        elem.css('transition', '');

        requestAnimationFrame(() => {
          elem.css('height', height);
        })
      })

      // Clear the saved height values after the transition
      elem.on('transitionend', () => {
        elem.css('height', '');
      });
    }
  }



  init()
}