import $ from "jquery";
import "slick-carousel";

export const miCarouselPartner = () => {
  const init = () => {
    $(document).on("ready", function () {
      try {
        carouselPartner();
      } catch (err) {
        console.error(err);
      }
    });
    // when window is resized
    $(window).on("resize", function () {
      // run our multi slide adaptive height function incase current slider active slides change height responsively
      multiSlideAdaptiveHeight(carouselPartner);
    });
  };
  const carouselPartner = $(".mi-carousel-partner__container")
    .on("init", function (slick) {
      // on init run our multi slide adaptive height function
      multiSlideAdaptiveHeight(this);
    })
    .on("beforeChange", function (slick, currentSlide, nextSlide) {
      // on beforeChange run our multi slide adaptive height function
      multiSlideAdaptiveHeight(this);
    })
    .slick({
      infinite: true,
      speed: 1200,
      slidesToShow: 1,
      adaptiveHeight: false,
      arrows: true,
      dots: true,
      autoplay: true,
      autoplaySpeed: 5500,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            adaptiveHeight: true,
            speed: 300,
          },
        },
      ],
    });

  // our multi slide adaptive height function passing slider object
  function multiSlideAdaptiveHeight(slider) {
    // set our vars
    let activeSlides = [];
    let tallestSlide = 0;

    // very short delay in order for us get the correct active slides
    setTimeout(function () {
      // loop through each active slide for our current slider
      $(".slick-track .slick-active", slider).each(function (item) {
        // add current active slide height to our active slides array
        activeSlides[item] = $(this).outerHeight();
      });

      // for each of the active slides heights
      activeSlides.forEach(function (item) {
        // if current active slide height is greater than tallest slide height
        if (item > tallestSlide) {
          // override tallest slide height to current active slide height
          tallestSlide = item;
        }
      });

      // set the current slider slick list height to current active tallest slide height
      if (window.innerWidth < 768) {
        $(".slick-list", slider).height(tallestSlide);
      } else {
        $(".slick-list", slider).css({ height: "100%" });
      }
    }, 10);
  }
  init();
};
